import axios from 'axios';



export const getalldata=async ()=>{
    try{
       return await axios.get(`http://localhost:1337/api/tests/1`);
    }catch(error){
        console.log('Eror while calling getUsers API',error)
    }
    
}
export const getalldata2=async ()=>{
    try{
       return await axios.get(`http://localhost:1337/api/tests/2`);
    }catch(error){
        console.log('Eror while calling getUsers API',error)
    }
    
}
export const getalldata3=async ()=>{
    try{
       return await axios.get(`http://localhost:1337/api/tests/3`);
    }catch(error){
        console.log('Eror while calling getUsers API',error)
    }
    
}
